<template>
    <div>
        <title-bar :title="$t('navigations.usedItems')"/>
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="['search', 'perPage','advancedFilter']"
                :addBtnLink="$helper.getAddUrl('used-items')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:partNumber="data">
                    {{ data.value.stockItem.partNumber }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.stockItem.description }}
                </template>
                <template v-slot:batchNumber="data">
                    {{ data.value.stockItem.batchNumber }}
                </template>
                <template v-slot:station="data">
                    {{ data.value.stockItem.station }}
                </template>
                <template v-slot:owner="data">
                    {{ data.value.stockItem.owner.name }}
                </template>
                <template v-slot:price="data">
                    {{ data.value.stockItem.price }}
                </template>
                <template v-slot:remark="data">
                    {{ data.value.remark }}
                </template>
                <template v-slot:quantity="data">
                    {{ data.value.quantity }}
                </template>
                <template v-slot:expiryDate="data">
                    <b-badge :variant="expiryStatus(data.value.stockItem.expiryDate)">
                        {{ $helper.formatDate(data.value.stockItem.expiryDate) }}
                    </b-badge>
                </template>
                <template v-slot:type="data">
                    <b-badge variant="primary">{{ data.value.type.replace('_', ' ') }}</b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <delete-btn @pressDelete="deleteUsedItem(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import DeleteBtn from "@/components/Buttons/DeleteBtn";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "AdminUsedItemsListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        DeleteBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            filterFields: [
                {
                    label: 'forms.station',
                    name: "stockItem.station",
                    value: null,
                    type: "station",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.owner',
                    name: "stockItem.owner",
                    value: null,
                    type: "entity",
                    entityName: "$StockItemOwners"
                },
                {
                    label: 'forms.customer',
                    name: "cwo.customer",
                    value: null,
                    type: "customer",
                },
                {
                    label: 'forms.type',
                    name: "type",
                    value: null,
                    type: "enum",
                    enumName: "used_item_status"
                },
                {
                    label: 'forms.expiryDate',
                    name: "stockItem.expiryDate",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.partNumber',
                    name: "stockItem.partNumber",
                    value: null,
                    type: "text"
                },
                {
                    label: 'forms.batchNumber',
                    name: "stockItem.batchNumber",
                    value: null,
                    type: "text"
                }
            ],
            advancedParams: [],
            columns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.partNumber"),
                    field: "partNumber",
                    sortable: false,
                },
                {
                    label: this.$t("forms.batchNumber"),
                    field: "batchNumber",
                    sortable: false,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.remark"),
                    field: "remark",
                    sortable: false,
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: false,
                },
                {
                    label: this.$t("forms.owner"),
                    field: "owner",
                    sortable: false,
                },
                {
                    label: this.$t("forms.price"),
                    field: "price",
                    sortable: false,
                },
                {
                    label: this.$t("forms.quantity"),
                    field: "quantity",
                    sortable: true,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.expiryDate"),
                    field: "expiryDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.usage"),
                    field: "type",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$UsedItems.getCollection({params}, "used_items_list").then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteUsedItem(url) {
            this.deleteByUrl(this.$UsedItems, url, this.$t("messages.usedItemDeleted"), null, this.load, this.error);
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        expiryStatus(date) {
            let className = 'success'
            let expiryDate = new Date(date)
            let currentDate = new Date()
            let twoMonths = 60 * 24 * 60 * 60 * 1000
            if (expiryDate.getTime() - currentDate.getTime() < twoMonths) {
                className = 'danger'
            }
            return className
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
